/* Global CSS */
html {
  /* scroll-behavior: smooth; */
}

body {
  font-family: 'Noto Sans KR', sans-serif !important;
  max-width: 100vw !important;
  color: #424242 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  margin-bottom: 1rem;
  font-size: 1rem;
}

table,
tbody,
th,
td {
  border: 1px solid #6c757d;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

center {
  margin: 2.5rem 0;
}
